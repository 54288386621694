/* NetworkStatus.css */
.network-status-container {
  width: 100%;
  position: relative;
  z-index: 1000;
  border-bottom: 1px solid #EC1C80;
}

.network-status-container.top {
  top: 0px;
}

.network-status-container.bottom {
  bottom: 20px;
}

.network-status-alert {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  margin: 0px;
  animation: slideIn 0.3s ease;
  background-color: #ffffff;
  border: none;
  border-radius: 0px;
}

.network-status-alert.error {
	margin: 0px;
}

.network-status-alert.warning {
  border-left-color: #ffbb33;
}

.network-status-alert.success {
  border-left-color: #00cc66;
}

.network-status-icon {
  display: inline-flex;
  margin-right: 10px;
}

.network-status-message {
  color: rgb(106, 117, 135);
  font-size: 14px;
  font-weight: 500;
  flex-grow: 1;
  text-align: center;
}

.network-status-close {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.network-status-close:hover svg {
  fill: #EC1C80;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}